<template>
    <base-compact-list
        :items="creditNotes"
        :is-loading="isLoadingCreditNotes"
        :initial-selected-item="creditNote"
        selectable
        @item-click="onSidebarItem"
        @load-more="onLoadMore"
        @item-check="onItemCheck"
        :initialize="initializeList"
    >
        <template #header="{ checkedItems }">
            <template v-if="!checkedItems.length">
                <base-dropdown size="sm" variant="default" class="bg-white">
                    <template #btn-content>
                        {{ statusText }}

                        <base-icon
                            name="line-icons:arrows:chevron-down"
                            variant="inherit"
                        />
                    </template>

                    <template #default="{ toggleDropdown }">
                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange()
                                }
                            "
                        >
                            {{ $t('creditNotes.list.allCreditNotes') }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(CREDIT_NOTE_STATUS.DRAFT)
                                }
                            "
                        >
                            {{ $t('creditNotes.list.draft') }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(CREDIT_NOTE_STATUS.OPEN)
                                }
                            "
                        >
                            {{ $t('creditNotes.list.open') }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(CREDIT_NOTE_STATUS.CLOSED)
                                }
                            "
                        >
                            {{ $t('creditNotes.list.closed') }}
                        </base-dropdown-item>
                    </template>
                </base-dropdown>

                <base-button
                    v-if="$acl.can('create_credit_notes')"
                    icon="line-icons:general:plus"
                    :label="$t('general.new')"
                    size="sm"
                    @click="goToCreditNoteCreate"
                />
            </template>
        </template>

        <template #item-content="{ item, clickItem }">
            <div class="w-full" @click="clickItem(item)">
                <div class="mb-1 flex w-full items-center justify-between">
                    <p class="text-sm text-gray-700">
                        {{
                            truncateString(
                                getContactName(
                                    item?.sale_document?.contact?.contact
                                ),
                                25
                            )
                        }}
                    </p>

                    <p class="text-sm text-gray-700">
                        {{
                            $filters.currency(
                                item?.sale_document?.amount_gross_total,
                                item?.sale_document?.currency?.iso
                            )
                        }}
                    </p>
                </div>

                <div class="flex w-full items-center justify-between">
                    <p class="text-xs">
                        <span class="text-primary-600">
                            {{ item?.sale_document?.number }}
                        </span>

                        <span class="px-2.5 text-gray-300">|</span>

                        <span class="text-gray-500">{{
                            $filters.dateHumanFormat(item?.date_of_credit_note)
                        }}</span>
                    </p>

                    <partial-status
                        :status="item.sale_document.status"
                        variant="small"
                    >
                        <template #text>{{ getStatusText(item) }}</template>
                    </partial-status>
                </div>
            </div>
        </template>
    </base-compact-list>
</template>

<script setup>
import { isEqual, uniqBy } from 'lodash-es'
import { useFilters } from '@tenant/composables'
import { useInfiniteQuery } from '@tanstack/vue-query'
import { getContactName, truncateString } from '@tenant/utils/helper'
import { useCreditNotes } from '@tenant/modules/tenant/credit-notes/composables/use-credit-notes'
import { CREDIT_NOTE_STATUS } from '@tenant/modules/tenant/credit-notes/utils/constants'
const { getStatusText } = useCreditNotes()

const props = defineProps({
    creditNote: {
        type: Object,
        default: () => null,
    },
})

const route = useRoute()
const router = useRouter()

// INFINITE SCROLL CREDIT NOTE LIST
const { useApiSearch } = useApiFactory('sale-documents/credit_notes')
const { execute } = useApiSearch({}, false)

const {
    data: creditNotes,
    isFetching: isLoadingCreditNotes,
    fetchNextPage: fetchMoreAccounts,
    hasNextPage: hasMoreAccounts,
    refetch,
} = useInfiniteQuery({
    queryKey: ['creditNotes'],
    queryFn: ({ pageParam = 1 }) => {
        return execute({
            page: pageParam,
            limit: 20,
            ...route.query,
        })
    },
    getNextPageParam: ({ current_page, last_page }) => {
        return current_page < last_page ? current_page + 1 : undefined
    },
    select: (res) =>
        uniqBy(
            res?.pages?.flatMap((x) => x?.data),
            (x) => x?.id
        ),
})

const onLoadMore = () => {
    if (hasMoreAccounts.value) {
        fetchMoreAccounts()
    }
}

// END: INFINITE SCROLL CREDIT NOTE LIST

const onSidebarItem = (item) => {
    router.push({
        name: 'credit-notes.dispatch',
        params: { id: item.id },
        query: { ...route.query },
    })
}

const goToCreditNoteCreate = () => {
    router.push({ name: 'credit-notes.create' })
}

// FILTER creditNotes
const queries = {
    type: 'filter.saleDocument.status',
    due_date: 'filter.due_date',
}
const { filterValues, updateFilters } = useFilters(queries)
const status = ref(null)
watch(
    () => filterValues.value,
    (value, oldValue) => {
        if (isEqual(value, oldValue)) return
        refetch()
    }
)
watch(
    () => props.creditNote?.sale_document?.status,
    () => {
        refetch()
    }
)

onMounted(() => {
    initStatus()
})

const initStatus = () => {
    const { type } = filterValues.value
    if (!type) {
        return
    }

    status.value = type
}

const filterChange = (updateStatus = null) => {
    const filters = {
        [queries.type]: updateStatus,
    }

    status.value = updateStatus
    updateFilters(filters)
}

const { t } = useI18n()
const statusText = computed(() => {
    if (status.value === CREDIT_NOTE_STATUS.OPEN) {
        return t('creditNotes.list.open')
    }

    if (status.value === CREDIT_NOTE_STATUS.CLOSED) {
        return t('creditNotes.list.open')
    }

    if (status.value === CREDIT_NOTE_STATUS.DRAFT) {
        return t('creditNotes.list.draft')
    }

    return t('creditNotes.list.allCreditNotes')
})
// END: FILTER creditNotes

const checkedItems = ref([])
const onItemCheck = ({ checkedItems: items }) => {
    checkedItems.value = items
}

const initializeList = ref(false)
</script>
