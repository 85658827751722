<template>
    <div class="my-6">
        <base-section-divider
            :text="$t('creditNotes.generalInfo.customerInfoTitle')"
        />
        <div class="grid grid-cols-2 items-start gap-x-4">
            <div class="grid grid-cols-1 gap-y-5">
                <form-contact-select
                    v-if="(contactId && contact) || !contactId"
                    v-model="contactId"
                    :label="$t('creditNotes.generalInfo.customerLabel')"
                    :placeholder="
                        $t('creditNotes.generalInfo.customerPlaceholder')
                    "
                    :error-message="errorContactId"
                    :input-display="getContactName(contact)"
                    @on-contact-changed="onContactChange"
                />

                <form-textarea
                    :label="$t('creditNotes.generalInfo.customerAddressLabel')"
                    :placeholder="
                        $t('creditNotes.generalInfo.customerAddressPlaceholder')
                    "
                    rows="6"
                    v-model="customerAddress"
                />

                <form-single-select
                    v-model="customerCountry"
                    :label="$t('creditNotes.generalInfo.customerCountryLabel')"
                    :options="countries"
                    :error-message="errorCustomerCountry"
                />
            </div>
            <div class="grid grid-cols-2 gap-x-3 gap-y-5">
                <form-text-input
                    :label="$t('creditNotes.generalInfo.creditNoteHeaderLabel')"
                    :placeholder="
                        $t(
                            'creditNotes.generalInfo.creditNoteHeaderPlaceholder'
                        )
                    "
                    v-model="creditNoteHeader"
                    :error-message="errorCreditNoteHeader"
                />
                <form-text-input
                    v-model="creditNoteNumber"
                    :label="$t('creditNotes.generalInfo.creditNoteNumberLabel')"
                    :placeholder="
                        $t(
                            'creditNotes.generalInfo.creditNoteNumberPlaceholder'
                        )
                    "
                    :error-message="errorCreditNoteNumber"
                    disabled
                />
                <form-date-picker
                    :label="$t('creditNotes.generalInfo.dateLabel')"
                    single
                    v-model="date"
                    :error-message="errorDate"
                />
                <form-text-input
                    :label="$t('creditNotes.generalInfo.referenceLabel')"
                    :placeholder="
                        $t('creditNotes.generalInfo.referencePlaceholder')
                    "
                    v-model="referenceNumber"
                    :error-message="errorReferenceNumber"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { dayjsFormat, now, shortServerFormat } from '@tenant/utils/day'
import {
    DATE_SERVER_FORMAT,
    DEFAULT_COUNTRY_ISO,
} from '@tenant/utils/constants'
import { getContactName } from '@tenant/utils/helper'

const { warningNotify } = useNotification()
const { countries } = useCountries()
const route = useRoute()

// FORM VALUES
const { value: customerAddress } = useField('address')
const { value: customerCountry, errorMessage: errorCustomerCountry } =
    useField('country_id')
const { value: currency } = useField('currency_id')
const { value: contactCurrency } = useField('contact_currency')
const { value: exchangeRate } = useField('exchange_rate')
const { value: creditNoteHeader, errorMessage: errorCreditNoteHeader } =
    useField('credit_note_header')
const { value: creditNoteNumber, errorMessage: errorCreditNoteNumber } =
    useField('number')
const { value: date, errorMessage: errorDate } = useField('date_of_credit_note')
const { value: referenceNumber, errorMessage: errorReferenceNumber } =
    useField('reference')
const { value: contactId, errorMessage: errorContactId } =
    useField('contact_id')
const { value: contact } = useField('contact')
// END FORM VALUES

// CONTACTS
const { useApiGet } = useApiFactory('contacts')
const { execute: executeGet, result } = useApiGet()

const emitter = useEmitter()
onMounted(async () => {
    emitter.emit('set-loading', true)

    if (route.query.contact_id) {
        contactId.value = route.query.contact_id
        await executeGet(contactId.value)
        contact.value = result.value
    }

    if (!date.value) {
        date.value = dayjsFormat(now(), DATE_SERVER_FORMAT)
    }

    try {
        await generateCreditNoteNumber()
    } catch ({ errorMessage }) {
        warningNotify({
            text: t('creditNotes.generalInfo.sequenceErrorMessage'),
        })
    }

    emitter.emit('set-loading', false)
})

const generateCreditNoteNumber = () => {
    const { execute } = useApi(
        '/api/sequence-settings/generate-number/credit_note',
        'GET'
    )

    return execute().then((response) => (creditNoteNumber.value = response))
}

/**
 * Set default country to Cyprus
 * @returns {*}
 */
const setDefaultCountry = () => {
    customerCountry.value = countries.value.find(
        (country) => country.iso === DEFAULT_COUNTRY_ISO
    )?.value
}

const isContactChanged = ref(false)
const onContactChange = ({ id, item }) => {
    contactId.value = id
    contact.value = item
}

watch(
    contact,
    () => {
        currency.value = contact.value?.currency_id
        contactCurrency.value = contact.value?.currency
        if (!exchangeRate.value) {
            exchangeRate.value = {
                date: shortServerFormat(contactCurrency.value?.rate_updated_at),
                rate: contactCurrency.value?.rate,
            }
        }

        if (!isContactChanged.value) return

        if (contact.value?.contact_addresses?.length > 0) {
            const { street, postcode, city, country_id } =
                contact.value.contact_addresses[0]
            if (street && postcode && city) {
                customerAddress.value = `${street}\n${postcode} ${city}`
            }

            if (country_id && customerCountry.value !== country_id) {
                customerCountry.value = country_id
            } else {
                setDefaultCountry()
            }
        } else {
            customerAddress.value = ''
            setDefaultCountry()
        }
    },
    {
        immediate: true,
    }
)
// END CONTACTS

// Auto-generate credit note number
const { t } = useI18n()
watch(creditNoteNumber, () => {
    creditNoteHeader.value = t(
        'creditNotes.generalInfo.creditNoteHeaderPlaceholderValue',
        {
            number: creditNoteNumber.value,
        }
    )
})

watch(
    countries,
    () => {
        if (!customerCountry.value) {
            setDefaultCountry()
        }
    },
    { immediate: true }
)
</script>
