export default {
    creditNotes: {
        sidebar: {
            label: 'Credit Notes',
        },
        title: 'Credit Notes',
        description: 'Sum of unpaid amounts: {sum}',
        itemCount: 'no credit notes | 1 credit note | {count} credit notes',
        emptyText: 'No credit notes found',
        emptySubText: 'Go ahead an create your first credit note',
        deleteModal: {
            title: 'Delete credit note',
            description: 'Are you sure you want to delete this credit note?',
        },
        deleteFailureModal: {
            title: 'Deletion Failure',
            description:
                'Credits that were already refunded cannot be deleted.',
        },
        headerContentTitle: 'Header content',
        footerContentTitle: 'Footer content',
        lineItemsTitle: 'Products and items',
        editExchangeRate: 'Edit Exchange Rate',
        exchangeRateLabel: 'Exchange Rate *',
        recalculateLabel: 'Re-calculate item prices based on this rate',
        moreOptionsLabel: 'More options',
        vatRuleTitle: 'VAT RULE',
        preview: 'Preview',
        proceedBtn: 'Proceed',
        lineItemsExchangeRate: 'Exchange Rate',
        list: {
            add: 'Add Credit Note',
            date: 'Date',
            number: 'No.',
            customer: 'Customer',
            status: 'Status',
            amountGross: 'Amount (Gross)',
            balance: 'Balance',
            viewAll: 'View All',
            draft: 'Draft',
            open: 'Open',
            closed: 'Closed',
            allCreditNotes: 'All Credit Notes',
            searchByNumber: 'Search by credit note number',
            filters: {
                netAmount: 'Amount (net)',
            },
        },
        create: {
            title: 'Create Credit Note',
        },
        edit: {
            title: 'Edit Credit Note',
        },
        dispatch: {
            draftNotice:
                'This is a DRAFT credit note. You can take further actions once you approve it.',
            refundBtn: 'Refund',
            applyBtn: 'Apply to invoices',
            edit: 'Edit',
            approve: 'Approve',
            transactions: {
                title: 'Refund history',
            },
        },
        applyCreditModal: {
            title: 'Apply Credits to Invoices',
            numberColumn: 'Invoice number',
            dateColumn: 'Invoice Date',
            amountColumn: 'Invoice Amount',
            amountDueColumn: 'Amount due',
            creditColumn: 'Amount to credit',
            creditsAppliedText: 'Credits Applied: {amount}',
            remainingCreditText: 'Remaining Credits: {amount}',
            applySuccessMsg: 'Credits have been applied to the invoice(s)',
        },
        sendDocumentModal: {
            sendSuccess: 'Credit note sent successfully',
        },
        refundModal: {
            title: 'Refund Credit Note',
            amountLabel: 'Amount *',
            paymentAccountLabel: 'Payment Account *',
            refundDateLabel: 'Refunded On *',
            referenceLabel: 'Reference',
            descriptionLabel: 'Description',
            refundBtn: 'Refund',
            refundSuccessMessage: 'The amount has been refunded',
        },
        creditedInvoices: {
            title: 'Invoices credited',
            date: 'Date',
            invoiceNo: 'Invoice No.',
            amount: 'Amount Credited',
            confirmText:
                'Are you sure about deleting the refund made for this credit note?',
            deleteSuccess: 'Credits applied to an invoice have been deleted.',
        },
        refundHistory: {
            dateColumn: 'Date',
            refundAmountColumn: 'Amount Refunded',
        },
        generalInfo: {
            customerInfoTitle: 'Credit note information',
            customerLabel: 'Customer *',
            customerPlaceholder: 'Search / create contact',
            customerAddressLabel: 'Address',
            customerAddressPlaceholder: 'Enter an address...',
            customerCountryLabel: 'Country *',
            creditNoteHeaderLabel: 'Credit note header',
            creditNoteHeaderPlaceholder: 'Credit note no. CR-0001',
            creditNoteNumberLabel: 'Credit note number *',
            creditNoteNumberPlaceholder: 'CR-0001',
            dateLabel: 'Date *',
            referenceLabel: 'Reference',
            referencePlaceholder: 'CY1245234',
            creditNoteHeaderPlaceholderValue: 'Credit note no. {number}',
            sequenceErrorMessage: 'Sequence setting for credit note not found.',
        },
    },
}
