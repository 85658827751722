<template>
    <base-button-group>
        <base-button
            variant="default"
            :class="{
                'bg-gray-100': !status,
            }"
            @click="viewChange()"
        >
            {{ $t('creditNotes.list.viewAll') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(CREDIT_NOTE_STATUS.DRAFT)"
            :class="{
                'bg-gray-100': hasStatus(CREDIT_NOTE_STATUS.DRAFT),
            }"
        >
            {{ $t('creditNotes.list.draft') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(CREDIT_NOTE_STATUS.OPEN)"
            :class="{
                'bg-gray-100': hasStatus(CREDIT_NOTE_STATUS.OPEN),
            }"
        >
            {{ $t('creditNotes.list.open') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(CREDIT_NOTE_STATUS.CLOSED)"
            :class="{
                'bg-gray-100': hasStatus(CREDIT_NOTE_STATUS.CLOSED),
            }"
        >
            {{ $t('creditNotes.list.closed') }}
        </base-button>
    </base-button-group>
</template>

<script setup>
import { useFilters } from '@tenant/composables'
import { CREDIT_NOTE_STATUS } from '@tenant/modules/tenant/credit-notes/utils/constants'

const queries = {
    type: 'filter.saleDocument.status',
}
const { filterValues, updateFilters } = useFilters(queries)

const status = ref(null)

onMounted(() => {
    initStatus()
})

const initStatus = () => {
    const { type } = filterValues.value
    if (!type) {
        return
    }

    status.value = type
}

const viewChange = (updateStatus = null) => {
    const filters = {
        [queries.type]: updateStatus,
    }

    status.value = updateStatus
    updateFilters(filters)
}

const hasStatus = (value) => {
    return status.value === value
}
</script>
