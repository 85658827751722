<template>
    <base-button
        variant="default"
        icon="line-icons:general:filter-lines"
        @click="toggleFilterMenu(true)"
    >
        <span class="whitespace-nowrap">
            <span>{{ $t('general.filters') }}</span>
            <span v-if="filterCount"> ({{ filterCount }})</span>
        </span>
    </base-button>

    <slide-out-menu-filters
        :show="showFilterMenu"
        @close="toggleFilterMenu(false)"
        @reset="onResetFilters"
    >
        <base-section vertical hide-info-block>
            <template #right>
                <form-control-group>
                    <form-control-group-row>
                        <form-filter-customer-select
                            v-model="contactId"
                            @update:modelValue="onChangeContact"
                        />
                    </form-control-group-row>

                    <form-control-group-row>
                        <form-filter-date-range
                            query-key="date_of_credit_note"
                            :query-value="queries.date_of_credit_note"
                        />
                    </form-control-group-row>

                    <hr class="my-2" />

                    <div class="flex flex-col">
                        <form-control-label
                            :label="$t('creditNotes.list.filters.netAmount')"
                        />

                        <form-control-group-row>
                            <form-filter-range
                                query-key="net_amount"
                                :query-value="queries.net_amount"
                            />
                        </form-control-group-row>
                    </div>
                </form-control-group>
            </template>
        </base-section>
    </slide-out-menu-filters>
</template>

<script setup>
const queries = {
    contact_id: 'filter.saleDocument.contact.contact_id',
    date_of_credit_note: 'filter.date',
    net_amount: 'filter.saleDocument.amount_net_total',
}

const { filterValues, filterCount, updateFilters, resetFilters } =
    useFilters(queries)

const showFilterMenu = ref(false)
const toggleFilterMenu = (value) => (showFilterMenu.value = value)
const contactId = computed(() => filterValues.value.contact_id)

const onChangeContact = async (value) => {
    await updateFilters({
        [queries.contact_id]: value,
    })
}
const onResetFilters = () => {
    resetFilters().then(() => {
        showFilterMenu.value = false
    })
}
</script>
