<template>
    <base-sticky-heading
        :title="$t('creditNotes.edit.title')"
        class="px-6 py-3.5"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button outline variant="default" @click="onCancel">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    outline
                    variant="default"
                    @click="onPreview"
                    icon="line-icons:general:search-lg"
                    :loading="isSaving"
                >
                    {{ $t('creditNotes.preview') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    @click="onProceed"
                    :loading="isSaving"
                >
                    {{ $t('creditNotes.proceedBtn') }}
                </base-button>
            </div>
        </template>

        <div class="p-6">
            <credit-notes-create-information v-if="creditNote" />
            <credit-notes-create-header-content />
            <credit-notes-create-line-items is-edit />
            <credit-notes-create-footer-content />
            <credit-notes-create-more-options />
            <credit-notes-create-amount />
        </div>
    </base-sticky-heading>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useForm } from 'vee-validate'
import { useHead } from '@vueuse/head'
import { dayjsFormat } from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'
import { isNil, omitBy } from 'lodash-es'

const { t } = useI18n()
const { errorNotify } = useNotification()
const router = useRouter()
const route = useRoute()
const { setValues, setErrors, values, handleSubmit } = useForm()
const { useApiGet } = useApiFactory('sale-documents/credit_notes')
const { execute: executeGet } = useApiGet()
const emitter = useEmitter()
const { defaultCurrency } = useCurrencies()

useHead({
    title: computed(
        () => values?.credit_note_header ?? t('creditNotes.edit.title')
    ),
})

const onCancel = () => {
    router.push({ name: 'credit-notes.list' })
}

const onPreview = handleSubmit(async ({ contact: _, ...values }) => {
    const data = await saveCreditNote(values)

    if (data?.id) {
        await router.push({
            name: 'credit-notes.dispatch',
            params: { id: data.id },
        })
    }
})

const onProceed = handleSubmit(async ({ contact: _, ...values }) => {
    const data = await saveCreditNote(values)

    if (data?.id) {
        await router.push({
            name: 'credit-notes.dispatch',
            params: { id: data.id },
        })
    }
})

const creditNote = ref(null)
onMounted(async () => {
    try {
        emitter.emit('set-loading', true)

        creditNote.value = await executeGet(route.params.id)
        // Convert line items' values to number
        const lineItems = creditNote.value.line_items.map((item) => ({
            ...item.sale_document_line_item,
            tax_rate: Number(item.sale_document_line_item.tax_rate),
            discount: Number(item.sale_document_line_item.discount),
            quantity: Number(item.sale_document_line_item.quantity),
        }))

        setValues({
            ...creditNote.value,
            ...creditNote.value.sale_document,
            contact_id: creditNote.value.sale_document.contact.contact_id,
            contact: creditNote.value.sale_document.contact.contact,
            line_items: lineItems,
            address: creditNote.value.sale_document.address,
            exchange_rate: creditNote.value.sale_document.exchange_rate,
            contact_currency: creditNote.value.sale_document.contact.currency,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

const { useApiSave } = useApiFactory('sale-documents/credit_notes')
const { execute, loading: isSaving, code, parameters } = useApiSave()

const saveCreditNote = async (values) => {
    try {
        return await execute(
            {
                ...omitBy(values, isNil),
                currency_id: values.currency_id ?? defaultCurrency.value.value,
                due_date: dayjsFormat(values.due_date, DATE_SERVER_FORMAT),
                date_of_credit_note: dayjsFormat(
                    values.date_of_credit_note,
                    DATE_SERVER_FORMAT
                ),
            },
            route.params.id
        )
    } catch ({ errors, errorMessage }) {
        setErrors(errors)
        errorNotify({
            text: errorMessage,
        })
    }
}
</script>
