const navigation = [
    {
        id: 'sas-credit-notes',
        path: 'credit-notes',
        label: 'creditNotes.sidebar.label',
        position: 30,
        parent: 'sas-sales-payment',
        permission: 'view_credit_notes',
    },
]

export default navigation
