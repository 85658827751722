<template>
    <div class="relative my-6">
        <base-section-divider :text="$t('creditNotes.moreOptionsLabel')" />
        <div class="grid gap-x-4">
            <base-accordion class="mb-4" trigger-classes="!items-start">
                <template #title>
                    <div
                        class="mb-3.5 w-full border-b pb-3.5 text-left text-xl font-semibold"
                    >
                        {{ $t('creditNotes.vatRuleTitle') }}
                    </div>
                </template>
                <template #content>
                    <div>
                        <div
                            class="pb-3"
                            v-for="rule in taxRules"
                            :key="rule.id"
                        >
                            <form-radio
                                size="md"
                                name="invoiceTax"
                                :text="rule.name"
                                :value="rule.id"
                                v-model="taxRule"
                            />
                        </div>
                    </div>
                </template>
            </base-accordion>
        </div>
    </div>
</template>
<script setup>
import { LINE_ITEM_TYPES } from '@tenant/modules/tenant/invoices/utils/constants'

// FORM VALUES
const { value: taxRule } = useField('tax_rule_id')
const { fields } = useFieldArray('line_items')
const { value: contact } = useField('contact')
// END FORM VALUES

// TAX RULES
const { taxRules, isLoading } = useTaxRules(['sales'])
const selectedTaxRule = computed(() => {
    return taxRules.value.find((rule) => rule.id === taxRule.value)
})

watch(
    isLoading,
    () => {
        if (!isLoading.value) {
            // Default selected tax rule will be the first one
            taxRule.value = taxRules.value[0].id
        }
    },
    { immediate: true }
)

// Update all line item's tax rate when selected tax rate change
watch(taxRule, () => {
    if (!selectedTaxRule.value) return

    fields.value.map((field) => {
        field.value.tax_rule_id = selectedTaxRule.value.id
        field.value.tax_rate =
            field.value.type === LINE_ITEM_TYPES.LINE_ITEM
                ? selectedTaxRule.value.tax_rate
                : 0
        return field
    })
})
// END TAX RULES

watch(
    contact,
    () => {
        if (!taxRules.value) return

        // Set default configuration from contact
        if (
            contact.value?.tax_rule_id &&
            taxRules.value.find((rule) => rule.id === contact.value.tax_rule_id)
        ) {
            taxRule.value = contact.value.tax_rule_id
        }
    },
    { deep: true }
)
</script>
