<template>
    <div class="flex h-screen" v-if="creditNote">
        <credit-notes-sidebar-list
            :credit-note="creditNote"
            @data-update="onDataUpdate"
        />
        <div class="grow">
            <base-heading
                :title="creditNote?.credit_note_header"
                class="mb-6 px-6 py-5"
            >
                <template #title>
                    <div class="flex items-center">
                        <strong class="mr-2 text-lg font-medium text-gray-900">
                            {{ creditNote?.credit_note_header }}
                        </strong>

                        <partial-status
                            :status="creditNote?.sale_document?.status"
                            variant="small"
                        >
                            <template #text>
                                {{ getStatusText(creditNote) }}
                            </template>
                        </partial-status>
                    </div>
                </template>

                <template #right>
                    <div class="flex gap-3">
                        <template
                            v-if="
                                creditNote?.sale_document?.status ===
                                CREDIT_NOTE_STATUS.DRAFT
                            "
                        >
                            <base-button
                                v-if="$acl.can('update_credit_notes')"
                                size="sm"
                                variant="default"
                                icon="line-icons:editor:pencil-01"
                                @click="editDraft"
                            >
                                {{ $t('creditNotes.dispatch.edit') }}
                            </base-button>

                            <base-button
                                v-if="$acl.can('update_credit_notes')"
                                size="sm"
                                @click="approveDraft"
                            >
                                {{ $t('creditNotes.dispatch.approve') }}
                            </base-button>
                        </template>

                        <template v-else>
                            <base-button
                                v-if="$acl.can('update_credit_notes')"
                                size="sm"
                                variant="default"
                                icon="line-icons:editor:pencil-01"
                                @click="editDraft"
                            >
                                {{ $t('general.edit') }}
                            </base-button>

                            <base-button
                                v-if="$acl.can('update_credit_notes')"
                                @click="showSendCreditNoteModal = true"
                                size="sm"
                                variant="default"
                                icon="line-icons:communication:mail-01"
                            >
                                {{
                                    creditNote?.sale_document?.sent_at
                                        ? $t('general.resend')
                                        : $t('general.send')
                                }}
                            </base-button>

                            <base-button
                                v-if="
                                    creditNote?.sale_document?.status ===
                                        CREDIT_NOTE_STATUS.OPEN &&
                                    $acl.can('update_credit_notes')
                                "
                                size="sm"
                                @click="showApplyCreditModal = true"
                            >
                                {{ $t('creditNotes.dispatch.applyBtn') }}
                            </base-button>

                            <base-button
                                @click="printDocument(base64PdfContent)"
                                size="sm"
                                variant="default"
                                icon="line-icons:media-&-devices:printer"
                            ></base-button>
                        </template>

                        <base-context-menu dropdown-classes="z-50">
                            <base-context-menu-item
                                v-if="
                                    creditNote?.sale_document?.status ===
                                        CREDIT_NOTE_STATUS.OPEN &&
                                    $acl.can('update_credit_notes')
                                "
                                icon="line-icons:files:file-04"
                                @click="showRefundModal = true"
                            >
                                {{ $t('creditNotes.dispatch.refundBtn') }}
                            </base-context-menu-item>
                            <base-context-menu-item
                                icon="line-icons:general:download-01"
                                @click="onDownload"
                            >
                                {{ $t('general.download') }}
                            </base-context-menu-item>
                        </base-context-menu>
                    </div>
                </template>
            </base-heading>

            <base-alert
                v-if="
                    creditNote?.sale_document?.status ===
                    CREDIT_NOTE_STATUS.DRAFT
                "
                class="mx-6 mb-6"
                variant="warning"
                :title="$t('creditNotes.dispatch.draftNotice')"
            />

            <div class="px-6 pb-6">
                <credit-notes-transactions-collapse
                    :invoices="creditNote?.invoices ?? []"
                    :loading="isLoading"
                    :key="$route.params.id"
                    @data-update="onDataUpdate"
                />

                <base-pdf-viewer
                    class="pb-6"
                    :content="base64PdfContent"
                    :loading="isLoading"
                />

                <journal-history-collapse
                    v-if="
                        creditNote?.sale_document?.status !==
                        CREDIT_NOTE_STATUS.DRAFT
                    "
                    class="mb-6"
                    :loading="isLoading"
                    :queries="{
                        filter: {
                            'journalEntry.journalable_id': route.params.id,
                            'journalEntry.transaction_type':
                                JOURNAL_TRANSACTION_TYPE.CREDIT_NOTE,
                        },
                    }"
                />
            </div>
        </div>
    </div>

    <credit-notes-send-email-modal
        :credit-note="creditNote"
        :show="showSendCreditNoteModal"
        :loading="isSendingCreditNote"
        @modal-close="showSendCreditNoteModal = false"
        @modal-confirm="onSendCreditNote"
    />

    <credit-notes-refund-modal
        v-if="creditNote"
        :credit-note="creditNote"
        :show="showRefundModal"
        @modal-close="showRefundModal = false"
        @on-refunded="onCreditNoteRefunded"
    />

    <credit-notes-apply-modal
        v-if="showApplyCreditModal"
        :show="showApplyCreditModal"
        :credit-note="creditNote"
        @modal-close="showApplyCreditModal = false"
        @modal-save="onAppliedCredit"
    />
</template>

<script setup>
import { serialize } from 'object-to-formdata'
import { useApi } from '@tenant/composables'
import { computed } from 'vue'
import { JOURNAL_TRANSACTION_TYPE } from '@tenant/utils/constants'
import { exportPDF, printDocument } from '@tenant/utils/helper'
import { useCreditNotes } from '@tenant/modules/tenant/credit-notes/composables/use-credit-notes'
import { CREDIT_NOTE_STATUS } from '@tenant/modules/tenant/credit-notes/utils/constants'

const { getStatusText } = useCreditNotes()

const route = useRoute()

const creditNote = ref(null)

useHead({
    title: computed(() => creditNote.value?.credit_note_header),
})

watch(
    () => route.params.id,
    async (id) => {
        if (route.name !== 'credit-notes.dispatch') {
            return
        }

        if (id) {
            await initializeData()
        }
    }
)

// GENERATE AND RENDER PDF
const { execute } = useApi(
    '/api/sale-documents/credit_notes/{id}/generate-pdf',
    'POST'
)

const base64PdfContent = ref(null)
const isLoading = ref(false)
const { errorNotify, successNotify } = useNotification()
const emitter = useEmitter()
const { t } = useI18n()

onMounted(async () => {
    await initializeData()
})
// END: GENERATE AND RENDER PDF

const initializeData = async () => {
    try {
        emitter.emit('set-loading', true)
        isLoading.value = true

        const result = await execute({
            params: {
                id: route.params.id,
            },
        })

        creditNote.value = result.document
        base64PdfContent.value = result.content
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    } finally {
        isLoading.value = false
        emitter.emit('set-loading', false)
    }
}

// HANDLE SEND CREDIT NOTE
const { handleSubmit, setErrors } = useForm({
    initialValues: {
        receiver: [],
    },
})
const showSendCreditNoteModal = ref(false)
const isSendingCreditNote = ref(false)
const { execute: executeSendCreditNote } = useApi(
    '/api/sale-documents/credit_notes/{id}/send',
    'POST'
)

const onSendCreditNote = handleSubmit((values) => {
    isSendingCreditNote.value = true

    return executeSendCreditNote({
        params: {
            id: creditNote.value.id,
        },
        headers: { 'Content-Type': 'multipart/form-data' },
        data: serialize(values),
    })
        .then(() => {
            initializeData()

            successNotify({
                title: t('creditNotes.sendDocumentModal.sendSuccess'),
            })

            showSendCreditNoteModal.value = false
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
        .finally(() => {
            isSendingCreditNote.value = false
        })
})
// END: HANDLE SEND CREDIT NOTE

// HANDLE APPROVE CREDIT NOTE
const { execute: executeApproveDraft } = useApi(
    '/api/sale-documents/credit_notes/{id}/approve-draft',
    'POST'
)

const router = useRouter()
const editDraft = () => {
    router.push({
        name: 'credit-notes.edit',
        params: {
            id: route.params.id,
        },
    })
}

const approveDraft = async () => {
    try {
        emitter.emit('set-loading', true)

        await executeApproveDraft({
            params: {
                id: creditNote.value.id,
            },
        })

        await initializeData()
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
}
// END: HANDLE APPROVE CREDIT NOTE

const onDataUpdate = async (cb) => {
    await initializeData()
    if (cb && cb instanceof Function) {
        cb()
    }
}

const onDownload = () => {
    exportPDF(
        `${creditNote.value.credit_note_header}.pdf`,
        base64PdfContent.value
    )
}

// REFUND
const showRefundModal = ref(false)
const onCreditNoteRefunded = async () => {
    showRefundModal.value = false
    await initializeData()
}
// END: REFUND

// APPLY TO INVOICES
const showApplyCreditModal = ref(false)

const onAppliedCredit = async () => {
    await initializeData()
    showApplyCreditModal.value = false
}
// END: APPLY TO INVOICES
</script>
