<template>
    <base-modal close @modal-close="closeModal" :show="show" v-if="creditNote">
        <form-control-group>
            <!-- Receiver -->
            <form-tags-input
                v-model="receiver"
                :label="$t('invoices.preview.sendDocument.receiver.label')"
                :placeholder="
                    $t('invoices.preview.sendDocument.receiver.placeholder')
                "
                :error-message="errorReceiver"
            />

            <!-- Subject -->
            <form-text-input
                v-model="subject"
                :label="$t('invoices.preview.sendDocument.subject.label')"
                :placeholder="
                    $t('invoices.preview.sendDocument.subject.placeholder')
                "
                :error-message="errorSubject"
            />

            <!-- Message -->
            <form-rich-text-editor
                v-model="message"
                :label="$t('invoices.preview.sendDocument.message.label')"
                :error-message="errorMessage"
            />
        </form-control-group>

        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    :disabled="loading"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button full-width :loading="loading" @click="onConfirm">
                    {{ $t('general.send') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { useTextTemplate } from '@tenant/composables/apis/use-text-template'

const emit = defineEmits(['modal-close', 'modal-confirm'])

const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    creditNote: {
        type: [Object, null],
        default: () => ({}),
    },
})

const closeModal = () => {
    emit('modal-close')
}

const onConfirm = () => {
    emit('modal-confirm')
}

const { value: receiver, errorMessage: errorReceiver } = useField('receiver')
const { value: subject, errorMessage: errorSubject } = useField('subject')
const { value: message, errorMessage } = useField('message')

watch(
    () => props.show,
    (value) => {
        if (value) {
            // Set first email from contact as default email address
            const defaultEmail =
                props.creditNote?.sale_document?.contact?.contact?.email?.[0]
            receiver.value = defaultEmail ? [defaultEmail.email] : []
        }
    }
)
const { getDefaultTextTemplate } = useTextTemplate()
onMounted(async () => {
    const textTemplates = await getDefaultTextTemplate('email', 'credit_notes')

    if (textTemplates.length) {
        message.value = textTemplates.find(
            (template) => template.line_item === 'message'
        )?.content
        subject.value =
            textTemplates.find((template) => template.line_item === 'subject')
                ?.content ?? props?.creditNote.credit_note_header
    }
})
</script>
