<template>
    <base-accordion
        ref="accordion"
        class="mb-6 rounded-md border border-gray-200"
        :icon-bordered="false"
        :trigger-classes="triggerClasses"
        @toggle="onToggle"
    >
        <template #left="{ isOpen }">
            <div class="flex gap-4">
                <div
                    v-if="transactions.length > 0 || !invoices.length"
                    class="flex items-center"
                    :class="{
                        'border-b-2 border-transparent pb-2': isOpen,
                        '!border-primary-700':
                            activeTab === 'transactions' && isOpen,
                    }"
                    @click="changeActiveTab($event, isOpen, 'transactions')"
                >
                    <p class="mr-1 text-xs">
                        {{ $t('creditNotes.dispatch.transactions.title') }}
                    </p>
                    <base-badge variant="info" :label="transactions.length" />
                </div>
                <div
                    v-if="invoices.length"
                    class="flex items-center"
                    :class="{
                        'border-b-2 border-transparent pb-2': isOpen,
                        '!border-primary-700':
                            activeTab === 'invoices' && isOpen,
                    }"
                    @click="changeActiveTab($event, isOpen, 'invoices')"
                >
                    <p class="mr-1 text-xs">
                        {{ $t('creditNotes.creditedInvoices.title') }}
                    </p>
                    <base-badge variant="info" :label="invoices.length" />
                </div>
            </div>
        </template>

        <template #icon="{ isOpen }">
            <div :class="{ 'pb-[10px]': isOpen }">
                <base-icon
                    v-if="isOpen"
                    name="line-icons:arrows:chevron-down"
                    size="sm"
                    variant="inherit"
                />
                <base-icon
                    v-else
                    name="line-icons:arrows:chevron-right"
                    size="sm"
                    variant="inherit"
                />
            </div>
        </template>

        <template #content>
            <data-grid
                v-if="activeTab === 'transactions' && transactions.length > 0"
                :columns="columns"
                :data-source="transactions"
                class="rounded-none border-x-0 border-b-0"
            >
                <template #column-date="{ item }">
                    {{ $filters.dateHumanFormat(item.payment_date) }}
                </template>

                <template #column-amount="{ item }">
                    {{
                        $filters.currency(
                            item.amount,
                            item.payment_transaction.currency.iso
                        )
                    }}
                </template>
            </data-grid>

            <data-grid
                v-if="activeTab === 'invoices' && invoices.length"
                :columns="invoicesColumns"
                :data-source="invoices"
                class="rounded-none border-x-0 border-b-0"
            >
                <template #column-date="{ item }">
                    {{ $filters.dateHumanFormat(item.pivot.date) }}
                </template>

                <template #column-amount="{ item }">
                    {{
                        $filters.currency(
                            item.pivot.credit,
                            item.sale_document.currency.iso
                        )
                    }}
                </template>

                <template #column-number="{ item }">
                    <router-link
                        class="text-primary-700"
                        :to="{
                            name: 'invoices.dispatch',
                            params: { id: item.id },
                        }"
                    >
                        {{ item.sale_document.number }}
                    </router-link>
                </template>

                <template #column-action="{ item }">
                    <base-icon
                        name="line-icons:general:trash-01"
                        size="sm"
                        variant="gray"
                        @click="onOpenDeleteCreditNoteModal(item)"
                    />
                </template>
            </data-grid>
        </template>
    </base-accordion>

    <base-delete-modal
        :show="showDeleteCreditModal"
        :name="deletingCredit?.sale_document?.number"
        @modal-close="onCloseDeleteCreditModal"
        @confirm-delete="onDeleteCredit"
        :loading="isRemovingCredit"
    >
        <template #description>
            {{ $t('creditNotes.creditedInvoices.confirmText') }}
        </template>
    </base-delete-modal>
</template>

<script setup>
const { t } = useI18n()
const emit = defineEmits(['data-update'])
const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    invoices: {
        type: Array,
        default: () => [],
    },
})

const accordion = ref(null)

// GET TRANSACTIONS
const columns = [
    {
        property: 'date',
        label: t('creditNotes.refundHistory.dateColumn'),
    },
    {
        property: 'amount',
        label: t('creditNotes.refundHistory.refundAmountColumn'),
    },
]

const invoicesColumns = [
    {
        property: 'date',
        label: t('creditNotes.creditedInvoices.date'),
    },
    {
        property: 'number',
        label: t('creditNotes.creditedInvoices.invoiceNo'),
    },
    {
        property: 'amount',
        label: t('creditNotes.creditedInvoices.amount'),
    },
]

const route = useRoute()
const { successNotify, errorNotify } = useNotification()

const { transactions, refetch } = useBookedTransactions(route.params.id)

watch(
    () => props.loading,
    (value) => {
        if (!value && accordion.value) {
            accordion.value.collapseAccordion()
        }

        if (value) {
            refetch()
        }
    }
)
// END: GET TRANSACTIONS

const triggerClasses = ref('p-4')
const onToggle = (isOpen) => {
    triggerClasses.value = isOpen ? 'p-4 pb-0' : 'p-4'
}

const activeTab = ref('transactions')

watchEffect(() => {
    activeTab.value = props.invoices.length ? 'invoices' : 'transactions'
})

const changeActiveTab = (event, isOpen, tab) => {
    if (isOpen) {
        event.stopPropagation()
    }
    activeTab.value = tab
}

const showDeleteCreditModal = ref(false)
const { execute: executeRemoveCredit, loading: isRemovingCredit } = useApi(
    '/api/credit-note/{id}/remove-invoice/{invId}',
    'POST'
)

const deletingCredit = ref(null)
const onOpenDeleteCreditNoteModal = (invoice) => {
    showDeleteCreditModal.value = true
    deletingCredit.value = invoice
}

const onCloseDeleteCreditModal = () => {
    showDeleteCreditModal.value = false
    deletingCredit.value = null
}

const onDeleteCredit = async () => {
    if (!deletingCredit.value?.pivot?.id) {
        return
    }

    try {
        await executeRemoveCredit({
            params: {
                id: route.params.id,
                invId: deletingCredit.value.pivot.id,
            },
        })

        successNotify({
            text: t('creditNotes.creditedInvoices.deleteSuccess'),
        })

        emit('data-update')
    } catch ({ errorMessage }) {
        deletingCredit.value = null
        errorNotify({
            text: errorMessage,
        })
    } finally {
        showDeleteCreditModal.value = false
    }
}
</script>
