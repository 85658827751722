export const LINE_ITEM_TYPES = {
    LINE_ITEM: 'line_item',
    DISCOUNT: 'discount',
    SURCHARGE: 'surcharge',
}

export const DEFAULT_COUNTRY_ISO = 'CY'

export const CREDIT_NOTE_STATUS = {
    DISPATCH: 'dispatch',
    DUE: 'due',
    PAID: 'paid',
    UNPAID: 'unpaid',
    PART_PAID: 'part-paid',
    REVERSAL: 'reversal',
    DRAFT: 'draft',
    OPEN: 'open',
    CLOSED: 'closed',
}
