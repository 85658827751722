export const useCreditNotes = () => {
    const getStatusText = (item) => {
        const { t } = useI18n()
        if (!item?.sale_document?.status) return

        return t(`general.statuses.${item?.sale_document?.status}`)
    }

    return {
        getStatusText,
    }
}
