<template>
    <div class="my-6">
        <base-section-divider :text="$t('creditNotes.footerContentTitle')" />
        <form-rich-text-editor
            v-model="footerContent"
            :error-message="errorFooterContent"
        />
    </div>
</template>

<script setup>
const props = defineProps({
    defaultContent: {
        type: String,
        default: '',
    },
})

const { value: footerContent, errorMessage: errorFooterContent } =
    useField('footer_content')

watch(
    () => props.defaultContent,
    (value) => {
        footerContent.value = value
    },
    { immediate: true }
)
</script>
